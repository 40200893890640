<template>
  <div class="dashboard">
    <b-container fluid>
      <div class="bienvenida">
        <span class="name">Hola {{ currentUser.name }},</span
        ><span class="hello"> ¿Qué necesitas hacer ahora?</span>
      </div>
      <div class="mb-2">
        <b-row class="list-dashboard mt-2" align-v="stretch">
          <b-col
            sm="3"
            lg
            class="list-item ml-1 mr-1 mb-1"
            v-b-modal="'asignar-trabajos'"
          >
            <div class="text-dark"><icon-tasks /></div>
            <div class="pt-05 pb-1"><span>Crear trabajo</span></div>
            <div class="icon-flecha"><icon-flecha /></div>
          </b-col>
          <b-col
            sm="3"
            lg
            class="list-item ml-1 mr-1 mb-1"
            v-b-modal="'crear-tarea'"
          >
            <feather-icon icon="CalendarIcon" size="22" />
            <div class="pt-05 pb-1"><span>Crear tarea</span></div>
            <div class="icon-flecha"><icon-flecha /></div>
          </b-col>

          <b-col sm="3" lg class="list-item ml-1 mr-1 mb-1">
            <b-link :to="{ name: 'jobs' }">
              <div class="text-dark">
                <feather-icon icon="ClipboardIcon" size="22" />
              </div>
              <div class="pt-05 pb-1">
                <span
                  >Ver listado <br />
                  de <strong>trabajos</strong></span
                ><br />
              </div>
              <div class="icon-flecha"><icon-flecha /></div>
            </b-link>
          </b-col>
          <b-col sm="3" lg class="list-item mr-1 ml-1 mb-1">
            <b-link :to="{ name: 'clients' }">
              <div class="text-dark">
                <feather-icon icon="UsersIcon" size="22" />
              </div>
              <div class="pt-05 pb-1">
                <span
                  >Ver listado <br />de
                  <span class="text-bold">clientes</span></span
                >
              </div>
              <div class="icon-flecha"><icon-flecha /></div>
            </b-link>
          </b-col>
          <b-col sm="3" lg class="list-item mr-1 ml-1 mb-1">
            <b-link :to="{ name: 'partesOperario' }">
              <div class="text-dark"><icon-tasks-pending /></div>
              <div class="pt-05 pb-1">
                <span
                  >Ver parte de<br />
                  horas de los <span class="text-bold">operarios</span></span
                >
              </div>
              <div class="icon-flecha"><icon-flecha /></div>
            </b-link>
          </b-col>
        </b-row>
      </div>
      <List />
    </b-container>
    <b-modal :title="'Crear tarea'" id="crear-tarea" size="lg" hide-footer>
      <form-create-task type="total" />
    </b-modal>
    <b-modal id="asignar-trabajos" size="lg" hide-footer>
      <validation-observer ref="createJob">
        <div class="asignar-trabajos-modal">
          <div class="text-center pb-2">
            <h1>Crear trabajo</h1>
            <span>Selecciona un cliente y obra para continuar</span>
          </div>
          <b-form
            class="mt-2"
            @submit.prevent="handleSubmit"
            @keydown.enter="handleSubmit"
          >
            <b-container class="pl-3 pr-3">
              <b-row>
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    :name="'Cliente'"
                    rules="required"
                  >
                    <b-form-group :label="'Cliente'" label-for="job-client">
                      <v-select
                        v-model="client"
                        label="name"
                        :filterable="false"
                        :searchable="false"
                        :options="selectClients"
                        :placeholder="'Nombre del cliente'"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    :name="'Obra'"
                    rules="required"
                  >
                    <b-form-group :label="'Obra'" label-for="job-project">
                      <v-select
                        v-model="project"
                        label="name"
                        :filterable="false"
                        :searchable="false"
                        :options="selectProjects"
                        :placeholder="'Nombre de la obra'"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="text-center mb-3">
                <b-col>
                  <b-button type="submit" variant="primary" class="mt-2 mr-1">
                    {{ "Continuar" }}
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
          </b-form>
        </div>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BLink,
  BModal,
  BFormGroup,
  BForm,
  BButton,
} from "bootstrap-vue";
import iconTasks from "@/assets/images/icons/talleres-mata/tareas_new";
import homeCircle from "@/assets/images/icons/talleres-mata/casita_con_circulo";
import iconTasksPending from "@/assets/images/icons/talleres-mata/tareas_pending";
import iconFlecha from "@/assets/images/icons/talleres-mata/icon-flecha-abajo";
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import router from "@/router";
import List from "@/views/projects/List";
import FormCreateTask from "@/views/tasks/FormCreateTask";

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    iconTasks,
    homeCircle,
    iconTasksPending,
    iconFlecha,
    BLink,
    BModal,
    BFormGroup,
    vSelect,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    List,
    FormCreateTask,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getUser",
      selectProjects: "projects/getSelectProjects",
      selectClients: "clients/getSelectClients",
    }),
  },
  data() {
    return {
      project: "",
      client: "",
      log: [],
    };
  },
  methods: {
    ...mapActions({
      getSelectProjects: "projects/getSelectProjects",
      getSelectClients: "clients/selectClients",
    }),
    handleSubmit() {
      this.$refs.createJob.validate().then((success) => {
        if (success) {
          router.push({
            name: "createJob",
            params: { project_param: this.project.id },
          });
        }
      });
    },
    async openCreateTask(job) {
      console.log(job);
      this.job = job;
      this.showCreateTask = true;
    },
  },
  watch: {
    client() {
      this.getSelectProjects({ client: this.client.id });
    },
  },
  async created() {
    await this.getSelectProjects({});
    await this.getSelectClients();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
